nav {
  padding: 0.5% 1%;
  position: sticky;
  z-index: 2;
  top: 0;
  align-items: center;
  background-color: #303237;
  display: grid;
  grid-template-columns: 0.2fr 1.2fr 0.1fr 0.2fr;
  flex-wrap: nowrap;
  font-size: 0.9em;
}

#logo {
  padding: 0;
}

#logo img {
  width: 120%;
}

#nav-links {
  display: flex;
  justify-content: center;
}

#nav-links a {
  margin-right: 5%;
  text-transform: uppercase;
  font-weight: bold;
}

#language-switch  {
  border: none;
  margin: 0;
  padding: 0 1% 0 0;
  width: auto;
  overflow: visible;
  background: transparent;
  /* inherit font & color from ancestor */
  color: inherit;
  font: inherit;
}

#language-switch span {
  cursor: pointer;
}

#nav-button {
  padding: 0.5em 1.5em;
  outline: none;
}

.active {
  color: #ffffff !important;
}

/* ==== Mobile Version ==== */

@media only screen and (max-width: 768px) {
  nav {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  }

  nav img {
    width: 10em;
    margin: 0 auto;
    grid-column: 2;
  }

  nav #language-switch {
    grid-column: 3;
    text-align: right;
    margin-right: 10%;
  }
}
