#content-container {
  width: 85%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 5%;
  align-items: center;
  margin: 5% auto 0;
}

#text-container {
  padding-bottom: 5%;
}

#text-container ul {
  padding: 0;
  margin: 0;
  text-align: center;
}

#text-container li {
  list-style: none;
  border-radius: 1em;
  margin-top: 2%;
}

#text-container li img {
  width: 3em;
  margin-right: 3%;
}

#why h3 {
  margin-bottom: 8%;
  width: 100%;
}

#video-container {
  border-radius: 3em;
  border: 1px solid #5a5959;
  box-shadow: 6px 6px 10px 5px rgba(0, 0, 0, 0.41);
  max-height: 317px;
}

#video-container iframe,
#video-container object,
#video-container embed {
  width: 100%;
  border-radius: 3em;
}

#why-button-wrap {
  width: 100%;
  text-align: center;
}

#why-button {
  display: inline-block;
  outline: none;
}

/* ==== Mobile Version ==== */

@media only screen and (max-width: 768px) {
  #why {
    margin: 30% auto;
    width: 90%;
    display: flex;
    flex-direction: column;
  }

  #content-container {
    display: block;
  }

  #text-container li {
    display: flex;
    flex-direction: column;
    margin-top: 2em;
  }

  #video-container {
    border-radius: 2em;
    overflow: hidden;
    max-height: 317px;
  }

  #video-container iframe,
  #video-container object,
  #video-container embed {
    border-radius: 2em;
  }

  #why button {
    margin-top: 3em;
  }
}
