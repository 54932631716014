* {
  margin: 0;
}

body {
  /* background-color: #176072; */
  background-color: #121212;
  /*   NIGHT  background: linear-gradient(to right, #0f0c29, #302b63, #24243e); */
  /* MORNING background: linear-gradient(to right, #FFC371, #FF5F6D); */
  /* overflow: hidden; */
  /* height: 100%; */
  font-family: "Raleway", sans-serif;
  color: #e9e9e9;
}

#root {
  height: 100%;
}
