#team-page {
  width: 100%;
}

#team-container {
  background-color: #303237;
  border-radius: 3em;
  padding: 5em;
  margin: 5% auto 5%;
  width: 85%;
}

#team-circle {
  position: relative;
  margin: auto;
  width: 25em;
  height: 25em;
  border: 1em solid #3e4147;
  border-radius: 50%;
  box-shadow: 0em 0em 2em #000000, 0 0 2em #000000 inset;
}

.member-circle {
  position: absolute;
  top: 7.5em;
  left: 7.5em;
  width: 8em;
  height: 8em;
  /*when changing the member-circle size, think of changing the top&left position: add or remove half of the circle width*/
  border: 0.1em solid rgb(109, 109, 109);
  border-radius: 50%;
  align-content: center;
  box-shadow: 0 0 0.3em rgb(208, 247, 252), 0 0 0.6em rgb(190, 243, 250),
    0 0 1.2em rgb(165, 250, 250);
  overflow: hidden;
}

.team-pic {
  height: 100%;
  width: 100%;
}

.deg0 {
  transform: translate(12em);
}
.deg72 {
  transform: rotate(72deg) translate(12em) rotate(-72deg);
}
.deg144 {
  transform: rotate(144deg) translate(12em) rotate(-144deg);
}
.deg216 {
  transform: rotate(216deg) translate(12em) rotate(-216deg);
}
.deg288 {
  transform: rotate(288deg) translate(12em) rotate(-288deg);
}

.team-member {
  position: absolute;
  margin: auto;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 12em;
  height: 5em;
  display: flex;
  align-items: center;
  justify-content: center;
}
.team-member h4 {
  width: 100%;
  margin: 0;
  font-size: 0.9em;
  font-weight: 500;
  margin-bottom: 0.2em;
  text-shadow: 0 0 0.4em rgb(253, 5, 121), 0 0 0.8em rgb(255, 1, 119),
    0 0 1.6em rgb(255, 0, 119);
  animation-name: pinkGlow;
  animation-duration: 1.8s;
  animation-iteration-count: infinite;
}
.team-member p {
  margin: 0;
  font-size: 0.8em;
  font-weight: 400;
}

#member1 {
  transform: translate(21rem);
}
#member2 {
  transform: rotate(45deg) translate(20rem) rotate(-45deg);
  margin: 7em auto 0;
}
#member3 {
  transform: rotate(155deg) translate(23.5rem) rotate(-155deg);
  text-align: end;
}
#member4 {
  transform: rotate(205deg) translate(23.5rem) rotate(-205deg);
}
#member5 {
  transform: rotate(315deg) translate(20rem) rotate(-315deg);
}

@keyframes pinkGlow {
  0% {
    text-shadow: none;
  }
  50% {
    text-shadow: 0 0 0.4em rgb(253, 5, 121), 0 0 0.8em rgb(255, 1, 119),
      0 0 1.6em rgb(255, 0, 119);
  }
  100% {
    text-shadow: none;
  }
}

/* ==== Mobile Version ==== */

@media only screen and (max-width: 768px) {
  #team-page {
    width: 95%;
    margin: 30% auto;
  }

  #team-box {
    /* background-color: #303237; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0 auto;
    border-radius: 2em;
    width: 100%;
  }

  .member-box {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    width: 100%;
    margin: 2em auto;
  }

  .image-box {
    height: 6em;
    width: 6em;
    border: 0.1em solid rgb(109, 109, 109);
    border-radius: 50%;
    align-content: center;
    box-shadow: 0 0 0.3em rgb(208, 247, 252), 0 0 0.6em rgb(190, 243, 250),
      0 0 1.2em rgb(165, 250, 250);
    overflow: hidden;
  }

  .member-text {
    max-width: 40%;
  }

  .member-id {
    margin-bottom: 0.5em;
    animation-name: pinkGlow;
    animation-duration: 1.8s;
    animation-iteration-count: infinite;
  }

  #team-page button {
    margin-top: 2em;
  }
}
