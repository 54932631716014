input[type=email] {
    display: inline-block;
    height: 100%;
    border-radius: 3em;
    border: 0;
    margin-bottom: 3%;
}

input::placeholder {
    font-size: 0.7em;
}

input:focus::placeholder {
    color: transparent;
}

#subscribe-button {
    padding: 0.5em 1.5em;
    font-size: 0.8em;
    outline: none;
}