/* ==== Desktop Version ==== */

#little-screens img {
  width: 50%;
  margin: 20% auto 15%;
  display: block;
}

#little-screens p {
  width: 60%;
  margin: 0 auto;
}

#main {
  padding-top: 1%;
  background: #121212;
  display: flex;
  align-items: center;
  justify-content: center;
}

#eltaskyline {
  background: rgb(91, 145, 158);
  background-image: linear-gradient(0deg, #5b919e 0%, #0e4654 100%);
  margin-bottom: -1px;
}

#page-container {
  display: grid;
  grid-template-rows: auto 1fr auto;
  height: 100vh;
}

button:focus {
  outline: none;
}

.button-design {
  border-radius: 3em;
  background-color: #ff4093;
  border: none;
  color: #fff;
  white-space: nowrap;
  font-size: 1em;
  padding: 0.5em 2em;
  font-weight: 500;
}

.button-design a {
  color: #fff;
}

.button-design:hover {
  background-color: #ea1e76cb;
}

h2 {
  font-size: 2em;
  font-weight: 300;
  width: 60%;
  margin: 3% auto;
}

h2,
h3,
h4 {
  text-align: center;
}

h3 {
  font-size: 1.5em;
  font-weight: 300;
  width: 60%;
  margin: 3% auto;
}

h4 {
  font-weight: 350;
  font-size: 1.1em;
  width: 40%;
  margin: 3% auto;
}

.h-container {
  margin-top: 8%;
}

p {
  font-weight: 500;
  font-size: 0.9em;
  text-align: center;
}

a,
a:hover,
a:visited {
  text-decoration: none;
}

nav a {
  color: #dbdadadc;
  font-size: 0.9em;
}

nav a:hover {
  color: #ffffff;
}

a {
    color: #ff4093;
    font-weight: bold;
}

a:hover {
    color: #ffffff;
    font-weight: bold;
}

.strike {
  text-decoration: line-through;
}

.animated {
  opacity: 0;
  animation: fadeIn 2s;
  animation-fill-mode: forwards;
}

.ref-wrapper-padded {
  padding-top: 8%;
}

/* ==== Mobile Version ==== */

@media only screen and (max-width: 768px) {
  .section {
    width: 100%;
    padding: 4em 0;
  }

  #section-presentation {
    padding-top: 0;
  }

  .dark-background {
    background-color: #121212;
  }

  .light-background {
    background-color: #171717;
  }

  .h-container {
    margin-bottom: 3em;
  }
  .h-container h2,
  h3,
  h4 {
    width: 90%;
    margin-bottom: 1.5em;
  }
  .h-container h2 {
    font-size: 1.7em;
  }

  .h-container h3 {
    font-size: 1.4em;
  }

  .h-container h4 {
    font-size: 1.1em;
  }

  .button-design {
    margin: 0.5em auto 0;
  }

  #mobile-page {
    display: grid;
    grid-template-rows: auto 1fr auto;
    height: 100vh;
  }

  #mobile-main {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  #eltaskyline img {
    margin-bottom: -1px;
  }

  .neon-flicker {
    text-transform: uppercase;
    animation: flicker 3s infinite;
  }

  @keyframes flicker {
    0%,
    31%,
    38%,
    56%,
    100% {
      text-shadow: rgb(0, 225, 255) 0px 0px 0.4em,
        rgb(0, 225, 255) 0px 0px 0.8em, rgb(0, 255, 255) 0px 0px 1.6em;
    }
    30%,
    37%,
    55% {
      text-shadow: none;
    }
  }
}
