#icon-container {
  text-align: center;
}

#icon-container i {
  padding: 1% 3%;
  animation-name: stim;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  transition: opacity 2s ease-in-out;
  cursor: pointer;
}

@keyframes stim {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(20px);
  }
  100% {
    transform: translateY(0);
  }
}

/* ==== Mobile Version ==== */

@media only screen and (max-width: 768px) {
}
