#input-container {
  text-align: center;
}

#input-container input {
  width: 30%;
  display: block;
  margin: 0 auto 2%;
}

#input-container p {
  margin-top: 3%;
  text-decoration: underline;
  cursor: pointer;
}

.notification {
  width: 60%;
  margin: 0 auto;
}

.failure {
  color: #b4b2b2;
}

.success {
  color: #ff4093;
}

/* ==== Mobile Version ==== */

@media only screen and (max-width: 768px) {
  #signup {
    width: 90%;
    margin: 30% auto;
  }

  #input-container input {
    width: 70%;
    margin-bottom: 0.7em;
  }

  .regular-steps {
    width: 100%;
    border-radius: 2em;
    padding: 0.1em 0 1.2em;
    margin-top: 1em;
  }
}
